import type { FC } from 'react';
import React, { useContext, useCallback, memo } from 'react';
import { styled } from '@compiled/react';
import { useQuery } from '@apollo/react-hooks';
import type { WrappedComponentProps } from 'react-intl-next';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl-next';
import { EngagementSpotlight } from '@atlassiansox/engagekit-ts';
import { mergeRefs } from 'use-callback-ref';

import { token } from '@atlaskit/tokens';
import { useAnalyticsEvents } from '@atlaskit/analytics-next/useAnalyticsEvents';
import { SpotlightTarget } from '@atlaskit/onboarding';
import { Profile, SignIn } from '@atlaskit/atlassian-navigation';
import Avatar from '@atlaskit/avatar/Avatar';
import Popup from '@atlaskit/popup';
import Lozenge from '@atlaskit/lozenge';

import {
	APP_NAV_PROFILE_DROPDOWN_EXPERIENCE,
	getExperienceTracker,
} from '@confluence/experience-tracker';
import { useRouteDataRef } from '@confluence/route-manager';
import { preloadProfileMenu } from '@confluence/nav-menus/entry-points/preloadProfileMenu';
import { useIsExternalCollaborator } from '@confluence/external-collab-ui/entry-points/useIsExternalCollaborator';
import { CONTEXT_PATH } from '@confluence/named-routes';
import { START_TOUCH } from '@confluence/navdex';
import { SPAViewContext } from '@confluence/spa-view-context';
import {
	useSSRPlaceholderReplaceIdProp,
	LoadableLazy,
	LoadableAfterPaint,
} from '@confluence/loadable';
import { AppNavigationContext } from '@confluence/app-navigation-context';
import { useRenderServerPlaceholder } from '@confluence/ssr-utilities';
import { fg } from '@confluence/feature-gating';

import type { MenuComponentProps } from './menuTypes';
import { AppNavigationUnifiedQuery } from './AppNavigationUnifiedQuery.graphql';

const loginLink =
	typeof window !== 'undefined'
		? `${CONTEXT_PATH}/login.action?os_destination=${window.location.pathname.replace(
				CONTEXT_PATH,
				'',
			)}`
		: '';

const i18n = defineMessages({
	signIn: {
		id: 'app-navigation.sign.in.tooltip',
		defaultMessage: 'Sign In',
		description: 'a tooltip directing the user to sign into the app',
	},
	profile: {
		id: 'app-navigation.profile.label',
		defaultMessage: 'Your profile and preferences',
		description: 'Label on the profile button',
	},
	guestLozenge: {
		id: 'app-navigation.profile.guest-lozenge',
		defaultMessage: 'Guest',
		description: 'Text for the lozenge that appears over the profile picture for guests',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledContainer = styled.div({
	maxHeight: 'calc(100vh - 76px)',
	color: token('color.text'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ProfilePlaceholder = styled.div({
	width: '36px',
	height: '36px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ProfileContainer = styled.div({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const GuestLozengeWrapper = styled.div({
	backgroundColor: token('color.background.accent.gray.subtlest'),
	borderRadius: '3px',
	position: 'absolute',
	bottom: token('space.050'),
});

const ProfileMenuLoader = LoadableLazy({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-confluencenav-menusentry-pointsProfileMenu" */ '@confluence/nav-menus/entry-points/ProfileMenu'
			)
		).ProfileMenu,
});

const Nav4OptOutSpotlightLoader = LoadableLazy({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-Nav4OptOutSpotlight" */ './Nav4OptOutSpotlight'))
			.Nav4OptOutSpotlight,
	loading: ({ children }) => <>{children}</>,
});

const Nav4OptOutSpotlightAfterPaintLoader = LoadableAfterPaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-Nav4OptOutSpotlight" */ './Nav4OptOutSpotlight'))
			.Nav4OptOutSpotlight,
	loading: ({ children }) => <>{children}</>,
});

const MemoizedProfileComponent: FC<MenuComponentProps & WrappedComponentProps> = memo(
	({ intl, openDialog, isOpen, onClose: onCloseCallback, onClick }) => {
		const ssrPlaceholderIdProp = useSSRPlaceholderReplaceIdProp();
		const routeDataRef = useRouteDataRef();
		const renderServerPlaceholder = useRenderServerPlaceholder();
		const { profileMenuButtonRef } = useContext(AppNavigationContext);
		const { createAnalyticsEvent } = useAnalyticsEvents();
		const { data, loading } = useQuery(
			// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
			AppNavigationUnifiedQuery,
		);
		const { isAnonymous, loading: contextLoading } = useContext(SPAViewContext);

		const { isExternalCollaborator } = useIsExternalCollaborator();

		const onClose = useCallback(() => {
			getExperienceTracker().abort({
				name: APP_NAV_PROFILE_DROPDOWN_EXPERIENCE,
				reason: 'dropdown closed by user',
				attributes: {
					navVersion: '3',
				},
			});

			onCloseCallback();
		}, [onCloseCallback]);

		const handleLoginClick = useCallback(() => {
			createAnalyticsEvent({
				type: 'sendUIEvent',
				data: {
					source: 'globalNavigation',
					action: 'clicked',
					actionSubject: 'navigationItem',
					actionSubjectId: 'login',
					attributes: {
						selectedItemPageContext: routeDataRef.current?.routeName,
						navigationLayer: 'global',
						navVersion: '3',
						navdexPointType: START_TOUCH,
					},
				},
			}).fire();
		}, [routeDataRef, createAnalyticsEvent]);

		const handleProfileClick = useCallback(() => {
			onClick();
			if (isOpen) {
				onClose();
			}
			createAnalyticsEvent({
				type: 'sendUIEvent',
				data: {
					source: 'globalNavigation',
					action: 'clicked',
					actionSubject: 'navigationItem',
					actionSubjectId: 'profile',
					attributes: {
						selectedItemPageContext: routeDataRef.current?.routeName,
						navigationLayer: 'global',
						navVersion: '3',
						navdexPointType: START_TOUCH,
						is_guest: isExternalCollaborator,
					},
				},
			}).fire();
		}, [isOpen, onClose, onClick, routeDataRef, createAnalyticsEvent, isExternalCollaborator]);

		const preloadMenu = useCallback(() => {
			void preloadProfileMenu();
			void ProfileMenuLoader.preload();
		}, []);

		if (
			loading ||
			contextLoading ||
			(renderServerPlaceholder && !fg('confluence_frontend_ssr_avatar'))
		) {
			return <ProfilePlaceholder />;
		}

		if (isAnonymous) {
			return (
				// eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
				<span data-testid="app-navigation-login" onClick={handleLoginClick}>
					<SignIn tooltip={intl.formatMessage(i18n.signIn)} href={loginLink} />
				</span>
			);
		} else {
			const user = data?.user || {
				photos: [],
				displayName: null,
				id: null,
			};

			const profileIconUrl = user?.photos?.[0]?.value;
			const profileTooltip = user.displayName ? user.displayName : null;

			const Icon = (
				<ProfileContainer>
					{isExternalCollaborator ? (
						<>
							<Avatar src={profileIconUrl} size="medium" testId="profile-component-navigation" />
							<GuestLozengeWrapper data-testid="guest-lozenge-indicator">
								<Lozenge>
									<FormattedMessage {...i18n.guestLozenge} />
								</Lozenge>
							</GuestLozengeWrapper>
						</>
					) : (
						<Avatar src={profileIconUrl} size="small" testId="profile-component-navigation" />
					)}
				</ProfileContainer>
			);

			const ChosenNav4OptOutSpotlightLoader = fg('confluence_frontend_ssr_avatar')
				? Nav4OptOutSpotlightAfterPaintLoader
				: Nav4OptOutSpotlightLoader;

			return (
				<Popup
					isOpen={isOpen}
					onClose={onClose}
					offset={[14, 0]}
					content={({ update: scheduleUpdate }) => (
						<StyledContainer>
							<ProfileMenuLoader
								onClose={onClose}
								dialogFn={openDialog}
								scheduleUpdate={scheduleUpdate}
							/>
						</StyledContainer>
					)}
					trigger={(triggerProps) => (
						<span
							data-testid="app-navigation-profile"
							data-vc="app-navigation-profile"
							{...ssrPlaceholderIdProp}
						>
							<ChosenNav4OptOutSpotlightLoader>
								<SpotlightTarget name="nav-profile">
									<Profile
										{...triggerProps}
										ref={
											profileMenuButtonRef
												? mergeRefs([triggerProps.ref, profileMenuButtonRef])
												: triggerProps.ref
										}
										icon={Icon}
										onClick={handleProfileClick}
										onFocus={preloadMenu}
										onMouseEnter={preloadMenu}
										tooltip={profileTooltip}
										isSelected={isOpen}
										aria-label={intl.formatMessage(i18n.profile)}
										aria-haspopup
										aria-expanded={isOpen}
									/>
								</SpotlightTarget>
							</ChosenNav4OptOutSpotlightLoader>
							<EngagementSpotlight engagementId="nav-profile" />
						</span>
					)}
					placement="bottom"
					shouldRenderToParent
				/>
			);
		}
	},
);

export const ProfileComponent = injectIntl(MemoizedProfileComponent);
